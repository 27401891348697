<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>案例管理</el-breadcrumb-item>
      <el-breadcrumb-item>案例列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 视图区 -->
    <el-card>

      <el-button type="primary"
                 class="addbtn"
                 @click="goAddPage">添加案例</el-button>

      <!-- 新闻列表 -->
      <el-table :data="showList"
                stripe
                style="width: 100%">
        <el-table-column type="index"
                         label="#"></el-table-column>
        <el-table-column prop="name"
                         label="案例名称"></el-table-column>

        <el-table-column label="首页展示"
                         prop="is_show">
          <template slot-scope="scope"
                    v-if="scope.row.status === 1 ">
            <el-switch v-model="scope.row.is_show"
                       :active-value="1"
                       :inactive-value="0"
                       @change="isShowChange(scope.row)"></el-switch>
          </template>
        </el-table-column>

        <el-table-column prop="catname"
                         label="案例类型"></el-table-column>

        <el-table-column label="是否显示"
                         prop="status">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"
                       :active-value="1"
                       :inactive-value="0"
                       @change="stateChange(scope.row)"></el-switch>
          </template>
        </el-table-column>

        <el-table-column prop="update_time"
                         label="更新时间"></el-table-column>
        <el-table-column label="操作"
                         width="200">
          <template slot-scope="scope">
            <el-button type="primary"
                       icon="el-icon-edit"
                       size="mini"
                       @click="editById(scope.row.id)">编辑</el-button>
            <el-button type="warning"
                       icon="el-icon-delete"
                       size="mini"
                       v-if="scope.row.status === 0"
                       @click="deleteById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showList: []
    }
  },
  methods: {
    async getShowList () {
      const { data: res } = await this.$http.get('project')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.showList = res.data
    },

    // 改变状态
    async stateChange (userinfo) {
      const { data: res } = await this.$http.put(`project/${userinfo.id}?status=${userinfo.status}`)
      if (res.code !== 200) {
        userinfo.status = !userinfo.status
        return this.$message.error('更新状态失败！')
      }
      this.$message.success('更新状态成功！')
      // 刷新列表
      this.getShowList()
    },

    // 首页展示
    async isShowChange (userinfo) {
      const { data: res } = await this.$http.put(`project/${userinfo.id}?is_show=${userinfo.is_show}`)
      if (res.code !== 200) {
        userinfo.is_show = !userinfo.is_show
        return this.$message.error('更新状态失败！')
      }
      this.$message.success('更新状态成功！')
      // 刷新列表
      this.getShowList()
    },

    // 删除管理员信息
    async deleteById (id) {
      const confirmResult = await this.$confirm('您确定要删除这条案例吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)

      // 如果用户取消了删除
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除！')
      }

      const { data: res } = await this.$http.delete('project/' + id, {
        headers: { Authorization: localStorage.getItem('token') }
      })
      if (res.code !== 200) {
        return this.$message.error('删除失败，请重试...')
      }
      this.$message.success('删除成功')
      this.getShowList()
    },

    goAddPage () {
      this.$router.push('/project/add')
    },

    // 修改产品
    editById (id) {
      this.$router.push(
        { path: '/project/edit/' + id }
      )
    }
  },

  created () {
    this.getShowList()
  }
}
</script>

<style scoped>
</style>
