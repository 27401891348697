<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>公司信息</el-breadcrumb-item>
      <el-breadcrumb-item>{{ pageTitle }}</el-breadcrumb-item>
    </el-breadcrumb>

    <el-form :model="dateForm"
             :rules="dateFormRules"
             ref="dateFormRef"
             label-position="top">
      <el-tabs type="border-card">
        <el-tab-pane label="案例信息">
          <el-row>
            <el-col :span="12">
              <el-form-item label="案例名称："
                            prop="name">
                <el-input v-model="dateForm.name"></el-input>
              </el-form-item>
              <el-form-item label="点间距："
                            prop="pixel">
                <el-input v-model="dateForm.pixel"></el-input>
              </el-form-item>
              <el-form-item label="案例所在地："
                            prop="loaction">
                <el-input v-model="dateForm.location"></el-input>
              </el-form-item>

              <el-form-item label="案例类型："
                            prop="cate_id">
                <el-select v-model="dateForm.cate_id"
                           placeholder="请选择案例类型">
                  <el-option v-for="item in cateList"
                             :key="item.id"
                             :label="item.catname"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>

              <!-- 图片上传 -->
              <el-form-item label="图片上传："
                            prop="logo">
                <el-upload :data="imgtype"
                           :action="uploadURL"
                           :headers="headerObj"
                           :limit="1"
                           :on-preview="handlePreview"
                           :before-upload="beforeUpload"
                           :on-success="handleSuccess"
                           :file-list="fileList"
                           list-type="picture">
                  <el-button size="small"
                             type="primary">点击上传</el-button>
                </el-upload>
                <el-dialog :visible.sync="imgDialogVisible"
                           width="50%"
                           append-to-body>
                  <img width="100%"
                       :src="dialogImageUrl"
                       alt="">
                </el-dialog>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="案例详情">
          <el-form-item label="详情介绍：">
            <TEditor v-model="dateForm.content"
                     :baseUrl="baseUrl" />
          </el-form-item>

          <el-form-item>
            <el-button type="primary"
                       @click="saveForm">提交</el-button>
            <el-button @click="goBack">取消</el-button>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 富文本上传路径
      baseUrl: '/logo?type=project',

      addData: '添加案例',
      updateData: '修改案例信息',
      pageTitle: '',

      dateForm: {
        name: '',
        pixel: '',
        location: '',
        cate_id: '',
        logo: '',
        content: ''
      },
      // 表单验证规则
      dateFormRules: {
        name: [
          { required: true, message: '请输入案例名称', trigger: 'blur' }
        ],
        pixel: [
          { required: true, message: '请输入案例点间距', trigger: 'blur' }
        ],
        location: [
          { required: true, message: '请输入案例位置', trigger: 'blur' }
        ],
        cate_id: [
          { required: true, message: '请选择分类', trigger: 'change' }
        ]
      },

      cateList: [],

      // 图片上传地址
      uploadURL: this.globalUrlapi + 'logo',
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      imgtype: {
        type: 'project'
      },
      fileList: [],
      imgDialogVisible: false,
      dialogImageUrl: ''
    }
  },

  methods: {
    goBack () {
      this.$router.push('/project')
    },

    // 获取当前id数据
    async getProjectList (id) {
      const { data: res } = await this.$http.get('project/' + id)
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.dateForm = res.data
      this.cateList = res.data.pid
      this.fileList.push({ name: res.data.pics_sma, url: this.globalUrlapi + res.data.pics_sma })
    },

    // 查看图片
    handlePreview (fileList) {
      this.dialogImageUrl = fileList.url
      this.imgDialogVisible = true
    },

    // 图片上传前校验
    beforeUpload (file) {
      const isIMAGE = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt1M = file.size / 1024 / 500 < 1
      if (!isIMAGE) {
        this.$message.error('上传文件只能是 jpg 或 png 图片格式!')
      }
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过 500kb!')
      }
      return isIMAGE && isLt1M
    },

    // 图片上传成功回调
    handleSuccess (response) {
      this.dateForm.logo = response.data
    },

    // 获取分类列表信息
    async getCateList () {
      const { data: res } = await this.$http.get('project/create')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请重新刷新网页重试...')
      }
      this.cateList = res.data
    },

    // 新增方法
    async addForm () {
      const { data: res } = await this.$http.post('project', this.dateForm)
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.$message.success('添加成功！')
      // 跳转
      this.$router.push('/project')
    },

    // 修改方法
    async updateForm () {
      const { data: res } = await this.$http.put('project/' + this.dateForm.id, {
        name: this.dateForm.name,
        pixel: this.dateForm.pixel,
        location: this.dateForm.location,
        cate_id: this.dateForm.cate_id,
        logo: this.dateForm.logo,
        content: this.dateForm.content
      })
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.$message.success('修改成功')
      // 跳转
      this.$router.push('/project')
    },

    init () {
      // 判断是否有参数
      if (this.$route.params && this.$route.params.id) {
        this.getProjectList(this.$route.params.id)
        this.getCateList()
        this.pageTitle = this.updateData
      } else {
        this.getCateList()
        this.pageTitle = this.addData
      }
    },

    saveForm () {
      this.$refs.dateFormRef.validate(valid => {
        if (!valid) return
        // 判断id是否存在  存在则为修改
        if (this.dateForm.id) {
          this.updateForm()
        } else {
          this.addForm()
        }
      })
    }

  },

  created () {
    this.init()
  }

}
</script>

<style scoped>
</style>
